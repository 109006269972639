import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#70AC00",
      100: "#E3F1C0",
      200: "#CFE897",
      300: "#BBDF69",
      400: "#A9D742",
      500: "#99D000",
      600: "#88C000",
      700: "#70AC00",
      800: "#599800",
      900: "#2B7600",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#253551",
      100: "#c0c8d3",
      200: "#98a4b5",
      300: "#738196",
      400: "#566782",
      500: "#394f71",
      600: "#324869",
      700: "#2b3e5e",
      800: "#253551",
      900: "#1d2538",
    },
    text: {
      primary: "rgba(96, 96, 96, 0.87)",
      secondary: "rgba(96, 96, 96, 0.6)",
      disabled: "rgba(96, 96, 96, 0.12)",
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    allVariants: {
      color: "#606060",
    },
  },
});
