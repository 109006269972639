import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Typography, Grid, Paper, Box, Tab, CircularProgress } from "@mui/material";
import moment from "moment-timezone";
import { subHours, format } from "date-fns";
import useHttp from '../../utils/useHttp';
import { IGps } from '../../interfaces/IGps';
import { ISpeed } from '../../interfaces/ISpeed';
import VehicleSpeedViolations from './VehicleSpeedViolations';
import VehicleSpeedData from './VehicleSpeedData';
import VehicleHeaders from './VehicleHeaders';
import { IVehicle } from '../../interfaces/IVehicle';
import { IHeader } from '../../interfaces/IHeader';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface Props {
  vehicle_vid: string;
  mergedBusData?: any[];
}

const VehicleDetails = ({ vehicle_vid, mergedBusData }: Props) => {
  const { loading, getData, cancelRequest } = useHttp();
  const [gps_data, setGPSData] = useState<Array<IGps>>();
  const [speed_data, setSpeedData] = useState<Array<ISpeed>>();
  const [vehicle_profile, setVehicleProfile] = useState<IHeader & IVehicle>();
  const [start, setStart] = useState(moment(subHours(new Date(), 72)).unix() * 1000);
  const [end, setEnd] = useState(moment().unix() * 1000);
  const [selected_tab, setSelectedTab] = useState("speed_data");
  const [dateValue, setDateValue] = useState<Dayjs | null>(dayjs(format(+end, "P")));
  
  const handleChange = (event: React.SyntheticEvent, tab_value: string) => {
    setSelectedTab(tab_value);
  };

  const handleDateChange = (newValue: Dayjs | null) => {
    if (newValue !== null) {
      setDateValue(newValue);
      setEnd(moment(subHours(new Date(newValue.format("MM-DD-YYYY")), -24)).unix() * 1000);
      setStart(moment(subHours(new Date(newValue.format("MM-DD-YYYY")), 72)).unix() * 1000);
    }
  };

  useEffect(() => {
    if (vehicle_vid !== "") {
      setStart(moment(subHours(new Date(), 72)).unix() * 1000);
      setEnd(moment().unix() * 1000);
      setDateValue(dayjs(format(+end, "P")));
      getVehicleProfile(vehicle_vid);
    }

    return () => {
      cancelRequest();
    };
  }, [vehicle_vid]);

  useEffect(() => {
    if (vehicle_vid !== "") {
      getVehicleProfile(vehicle_vid);
    }

    return () => {
      cancelRequest();
    };
  }, [vehicle_vid, end, start]);

  const getVehicleSpeed = (vid: string) => {
    getData("time/" + vid, {
      label: "speed",
      start: start,
      end: end,
      step: 5000,
    }, {
      "x-api-key": process.env.REACT_APP_VIRICITY_API_KEY
    })
      .then((res: Array<ISpeed>) => {
        let sorted_speed = res.sort((a, b) => Number(b.time) - Number(a.time));
        setSpeedData(sorted_speed);
      })
      .catch((error) => {
        console.log(error);
      });

    return speed_data;
  }

  const getVehicleGPS = (vid: string) => {
    getData("time/" + vid, {
      label: "gps",
      start: start,
      end: end,
      step: 5000,
    }, {
      "x-api-key": process.env.REACT_APP_VIRICITY_API_KEY
    })
      .then((res: Array<IGps>) => {
        let sorted_gps = res.sort((a, b) => Number(b.time) - Number(a.time));
        setGPSData(sorted_gps);
      })
      .catch((error) => {
        return error;
      });
    return gps_data;
  }

  const getVehicleProfile = (vid: string) => {
    getVehicleGPS(vid);
    getVehicleSpeed(vid);
    getMergedVehicleData(vid);
  }

  const getMergedVehicleData = (vid: string) => {
    const mergedVehicleData = mergedBusData && mergedBusData.filter((vehicleData: IVehicle) => vehicleData.vid === vid);
    setVehicleProfile(mergedVehicleData?.[0]);
  }


  return (
    <Grid component={Paper}>
      {
        vehicle_profile &&
        <VehicleHeaders vehicleProfile={vehicle_profile} />
      }
      <Box sx={{ margin: '1rem' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <DatePicker
            label="Date"
            inputFormat="MM/DD/YYYY"
            value={dateValue}
            onChange={handleDateChange}
            renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabContext value={selected_tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="Bus speed data and violations">
              <Tab label="Speed Data" value="speed_data" />
              <Tab label="Violations" value="speed_violations" />
            </TabList>
          </Box>
          {loading ?
            <Grid p={2}>
              <CircularProgress variant='indeterminate' size={11} />
              <Typography variant='caption' color={'GrayText'}> Loading...</Typography>
            </Grid>
            :
            <>
              <TabPanel value="speed_data">
                <VehicleSpeedData
                  speed_data={speed_data}
                  gps_data={gps_data}
                />
              </TabPanel>
              <TabPanel value="speed_violations">
                <VehicleSpeedViolations speed_data={speed_data} />
              </TabPanel>
            </>
          }
        </TabContext>
      </Box>
    </Grid>
  )
}

export default VehicleDetails