import { useState } from "react";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const useHttp = () => {
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const controller = new AbortController();

  const getData = async (url: string, params?: {}, headers?: {}) => {
    setLoading(true);
    try {
      let res = await axios({
        url: url,
        method: "GET",
        params: params,
        headers: headers,
      });
      let data = res.data;
      // console.log(JSON.parse(data));
      setLoading(false);
      return data;
    } catch (err) {
      setLoading(false);
      setError(true);
      throw err;
    }
  };

  const cancelRequest = () => {
    return controller.abort();
  }

  return {loading, error, getData, cancelRequest};
};

export default useHttp;