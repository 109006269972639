import React, { useRef } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Typography } from "@mui/material";
import { format } from "date-fns";

import { IGps } from '../../interfaces/IGps';
import { ISpeed } from "../../interfaces/ISpeed";

interface Props {
    gps_data?: IGps[];
    speed_data?: ISpeed[];
}

const VehicleSpeedData = ({ gps_data, speed_data }: Props) => {
    return (
        <>
            <Table aria-label="vehicle-details-table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 550 }}>Date</TableCell>
                        <TableCell sx={{ fontWeight: 550 }}>Time</TableCell>
                        <TableCell sx={{ fontWeight: 550 }}>Speed (km/h)</TableCell>
                        <TableCell sx={{ fontWeight: 550 }}>Latitude</TableCell>
                        <TableCell sx={{ fontWeight: 550 }}>Longitude</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {gps_data && gps_data.length > 0 ? speed_data && speed_data.map((speed: ISpeed, index: number) => (
                        <TableRow key={speed?.time}>
                            <TableCell>{format(+speed.time, "P")}</TableCell>
                            <TableCell>{format(+speed.time, "hh:mm:ss a")}</TableCell>
                            <TableCell>{Number(speed.value).toFixed(2)}</TableCell>
                            <TableCell>
                                {Number(gps_data[index]?.value.split("|")[0]).toFixed(4)}&#176;
                                {Number(gps_data[index]?.value.split("|")[0]) > 0 ? "N" : "S"}
                            </TableCell>
                            <TableCell>
                                {Number(gps_data[index]?.value.split("|")[1]).toFixed(4)}&#176;
                                {Number(gps_data[index]?.value.split("|")[1]) > 0 ? "E" : "W"}
                            </TableCell>
                        </TableRow>
                    ))
                        :
                        <TableRow>
                            <TableCell colSpan={4}>
                                <Typography variant='caption'>Vehicle speed data unavailable</Typography>
                            </TableCell>
                        </TableRow>
                    }
                </TableBody>
            </Table>
        </>
    )
}

export default VehicleSpeedData