import { Grid, Typography, Box } from "@mui/material";

import { IHeader } from "../../interfaces/IHeader";
import { IVehicle } from "../../interfaces/IVehicle";
import { InfoCard } from "../Card";

interface Props {
    vehicleProfile: IHeader & IVehicle;
}

const VehicleHeaders = ({ vehicleProfile }: Props) => {
    return (
        <Grid container p={2}>
            <Grid item md={3}>
                <Box mb={1}>
                    <Typography variant='caption' color={'GrayText'}>Bus Name</Typography>
                    <Typography variant='body2' fontWeight={600}>
                        {vehicleProfile?.name ? vehicleProfile?.name : "-"}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <Typography variant='caption' color={'GrayText'}>Reg No.</Typography>
                    <Typography variant='body2' fontWeight={600}>
                        {vehicleProfile?.fields?.busRegistrationNumber ? vehicleProfile?.fields?.busRegistrationNumber : "-"}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <Typography variant='caption' color={'GrayText'}>Chassis No.</Typography>
                    <Typography variant='body2' fontWeight={600}>
                        {vehicleProfile?.fields?.chassisNumber ? vehicleProfile?.fields?.chassisNumber : "-"}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <Typography variant='caption' color={'GrayText'}>Owner Phone No.</Typography>
                    <Typography variant='body2' fontWeight={600}>
                        {vehicleProfile?.fields?.busOwnerPhoneNumber ? vehicleProfile?.fields?.busOwnerPhoneNumber : "-"}
                    </Typography>
                </Box>
                </Grid>

                <Grid item md={3}>
                <Box mb={1}>
                    <Typography variant='caption' color={'GrayText'}>Limiter Type</Typography>
                    <Typography variant='body2' fontWeight={600}>
                        {vehicleProfile?.fields?.limiterType ? vehicleProfile?.fields?.limiterType : "-"}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <Typography variant='caption' color={'GrayText'}>Certificate No.</Typography>
                    <Typography variant='body2' fontWeight={600}>
                        {vehicleProfile?.fields?.certificateNumber ? vehicleProfile?.fields?.certificateNumber : "-"}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <Typography variant='caption' color={'GrayText'}>Limiter serial</Typography>
                    <Typography variant='body2' fontWeight={600}>
                        {vehicleProfile?.fields?.limiterSerial ? vehicleProfile?.fields?.limiterSerial : "-"}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <Typography variant='caption' color={'GrayText'}>Date of Fitting</Typography>
                    <Typography variant='body2' fontWeight={600}>
                        {vehicleProfile?.fields?.dateOfFitting ? (vehicleProfile?.fields?.dateOfFitting)?.toString() : "-"}
                    </Typography>
                </Box>
                </Grid>

                <Grid item md={3}>
                <Box mb={1}>
                    <Typography variant='caption' color={'GrayText'}>Fitting Agent</Typography>
                    <Typography variant='body2' fontWeight={600}>
                        {vehicleProfile?.fields?.fittingAgentName ? vehicleProfile?.fields?.fittingAgentName : "-"}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <Typography variant='caption' color={'GrayText'}>Fitting Agent ID</Typography>
                    <Typography variant='body2' fontWeight={600}>
                        {vehicleProfile?.fields?.chassisNumber ? vehicleProfile?.fields?.chassisNumber : "-"}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <Typography variant='caption' color={'GrayText'}>Fitting Agent Phone No</Typography>
                    <Typography variant='body2' fontWeight={600}>
                        {vehicleProfile?.fields?.agentPhoneNumber ? vehicleProfile?.fields?.agentPhoneNumber : "-"}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <Typography variant='caption' color={'GrayText'}>Fitting Agent Email</Typography>
                    <Typography variant='body2' fontWeight={600}>
                        {vehicleProfile?.fields?.emailAddress ? vehicleProfile?.fields?.emailAddress : "-"}
                    </Typography>
                </Box>
                </Grid>

                <Grid item md={3}>
                <Box mb={1}>
                    <Typography variant='caption' color={'GrayText'}>Station Name</Typography>
                    <Typography variant='body2' fontWeight={600}>
                        {vehicleProfile?.fields?.nameOfStation ? vehicleProfile?.fields?.nameOfStation : "-"}
                    </Typography>
                </Box>
                <Box mb={1}>
                    <Typography variant='caption' color={'GrayText'}>Station Location</Typography>
                    <Typography variant='body2' fontWeight={600}>
                        {vehicleProfile?.fields?.locationOfStation ? vehicleProfile?.fields?.locationOfStation : "-"}
                    </Typography>
                </Box>
                </Grid>
        </Grid>
    )
}

export default VehicleHeaders