import { useAuth0 } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";
import LoginButton from "./LoginButton";

const SignIn = () => {
  const { isAuthenticated } = useAuth0();

  return <>{!isAuthenticated ? <LoginButton /> : <Navigate to={"/dashboard"} />}</>;
};

export default SignIn;