import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Toolbar, Typography, Box, IconButton, Avatar } from "@mui/material";
import { useAuth0 } from '@auth0/auth0-react';

const Navbar = () => {
    const { user, logout } = useAuth0();
    const [userMenu, setUserMenu] = useState(null);
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenUserMenu = (event: any) => {
        setUserMenu(event.currentTarget);
      };

    const handleCloseUserMenu = () => {
        setUserMenu(null);
    };

    const handleSignOut = () => {
        console.log("Sign out");
        logout();
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
      };

    return (
        <AppBar position="sticky" color="inherit">
            <Toolbar>
        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => console.log("Menu")}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
          SpeedChecker
        </Typography>
        <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
          <Typography variant="body2">
            <Box component="span" sx={{ fontWeight: 600, color: "secondary.main", marginLeft: 1 }}>
              NTSA
            </Box>
            <Box component="span"> &nbsp;| &nbsp;</Box>
            <Box component="span" color="GrayText">
              BasiGo
            </Box>
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }} mr={2}>
          <Typography variant="body1" color={"secondary"} fontWeight="600">
          {user?.name}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt={user?.name} src={user?.picure} />
          </IconButton>
          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={userMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(userMenu)}
            onClose={handleCloseUserMenu}
            elevation={0}
          >
            <MenuItem onClick={handleCloseNavMenu}>
              <Typography textAlign="center" onClick={handleSignOut}>
                Sign out
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
      </AppBar>
    )
}

export default Navbar;
