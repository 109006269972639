import { Container } from "./SplitScreen.styles"

const LeftComponent = ({ children }: any) => {
  return (
    <>
      {children}
    </>
  )
}

export default LeftComponent