import React, { PropsWithChildren } from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
`;

const Pane = styled.div<{weight: any}>`
    flex: ${props => props.weight};
`;

interface ISplitScreen extends PropsWithChildren {
    children: any;
    leftWeight?: number;
    rightWeight?: number;
}

export const SplitScreen = (props: ISplitScreen) => {
    const [left, right] = props.children;
    return (
        <Container>
            <Pane weight={props.leftWeight}>
                {left}
            </Pane>
            <Pane weight={props.rightWeight}>
                {right}
            </Pane>
        </Container>
    );
}