import { IVehicle } from '../../interfaces/IVehicle';
import { IHeader } from '../../interfaces/IHeader';
import { TableRow, TableCell, Button } from "@mui/material";

interface Props {
  vehicleData: IHeader & IVehicle;
  getVehicleDetails: (vid: string) => void;
}

const VehicleListItem = ({ vehicleData, getVehicleDetails }: Props) => {

  return (
    <TableRow sx={{ "& td":{border:0} }} key={vehicleData._id}>
      <TableCell>{vehicleData?.fields?.busRegistrationNumber}</TableCell>
      <TableCell>{vehicleData.name}</TableCell>
      <TableCell>{vehicleData?.fields?.busMakeType}</TableCell>
      <TableCell colSpan={2}>{vehicleData?.fields?.busOwnerName}</TableCell>
      <TableCell colSpan={2}>
        <Button
          onClick={() => getVehicleDetails(vehicleData.vid)}
        >
          View
        </Button>
      </TableCell>
    </TableRow>
  )
}

export default VehicleListItem