import React from "react";
import { Route, Routes } from "react-router-dom";

import Dashboard from './pages/Dashboard';
import Login from './pages/Login';

import './App.css';

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </>
  );
}

export default App;
