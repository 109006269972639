import React from 'react';
import styled from "styled-components";

import BasiGoLogo from '../assets/basigo_logo.png';
import NTSALogo from '../assets/ntsa_logo.png';
import SignIn from '../components/auth/SignIn';

const Container = styled.div`
    display: flex;
    gap: 2.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    max-height: 100vh;
`;

const LoginBody = styled.div`
    background: #ffffff;
    padding-block: 10rem;
    padding-inline: 3.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    box-shadow: 0 12px 40px rgba(0,0,0,0.12);
`;

const ImgContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1.5rem;
`;

const Img1 = styled.img`
    width: 120px;
    height: 50px;
`;

const Img2 = styled(Img1)`
    height: 30px;
`;

const Login = () => {
    return (
        <Container>
            <LoginBody>
                <ImgContainer>
                    <Img1 src={NTSALogo} alt="NTSA Logo" />
                    <Img2 src={BasiGoLogo} alt="BasiGo Logo" />
                </ImgContainer>
                <SignIn />
            </LoginBody>
        </Container>
    )
}

export default Login
