import { Table, TableHead, TableRow, TableCell, TableBody, Typography } from "@mui/material";
import { format } from "date-fns";

import { ISpeed } from "../../interfaces/ISpeed";

interface Props {
    speed_data?: ISpeed[];
}

const VehicleSpeedViolations = ({ speed_data }: Props) => {
    const violationsData = speed_data && speed_data.filter((speed: any) => speed.value > 80)

    return (
        <Table aria-label="vehicle-details-table">
            <TableHead>
                <TableRow>
                    <TableCell sx={{ fontWeight: 550 }}>Date</TableCell>
                    <TableCell sx={{ fontWeight: 550 }}>Time</TableCell>
                    <TableCell sx={{ fontWeight: 550 }}>Speed (km/h)</TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {violationsData && violationsData.length > 0 ? violationsData.map((speed: ISpeed, index: any) => (
                    <TableRow key={index}>
                        <TableCell>{format(+speed.time, "P")}</TableCell>
                        <TableCell>{format(+speed.time, "hh:mm:ss a")}</TableCell>
                        <TableCell>{Number(speed.value).toFixed(2)}</TableCell>
                    </TableRow>
                ))
                    :
                    <TableRow>
                        <TableCell colSpan={4}>
                            <Typography variant='caption'>No speed violations available</Typography>
                        </TableCell>
                    </TableRow>
                }
            </TableBody>
        </Table>
    )
}

export default VehicleSpeedViolations