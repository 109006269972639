import React from "react";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAuth0 } from "@auth0/auth0-react";
import styled from "styled-components";

export const Container = styled.div`
    // background: #ffffff;
    // padding: 7.5rem;
    // border-radius: 1rem;
`;

const LoginButton = () => {
    const { isLoading, loginWithRedirect, isAuthenticated } = useAuth0();

    return (
        <>
            {!isAuthenticated && (
                <Container>
                    <Box>
                        <Typography gutterBottom align="center" mb={4} variant="h5" fontWeight={300}>
                            NTSA Speed Tracker
                        </Typography>
                    </Box>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        color="primary"
                        size="small"
                        onClick={() => loginWithRedirect()}
                        loading={isLoading}
                        fullWidth
                        disableElevation
                    >
                        Log In
                    </LoadingButton>
                </Container>
            )}
        </>
    );
};

export default LoginButton;