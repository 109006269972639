import { useEffect, useState } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, CircularProgress, Typography, Paper } from "@mui/material";
import Box from '@mui/material/Box';
import { createClient } from 'contentful';
import { useAuth0 } from "@auth0/auth0-react";

import useHttp from '../utils/useHttp';
import { SplitScreen } from '../layouts/SplitScreen';
import Navbar from '../components/navbar/Navbar';
import LeftComponent from '../layouts/SplitScreen/LeftComponent';
import RightComponent from '../layouts/SplitScreen/RightComponent';
import VehicleListItem from '../components/vehicles/VehicleListItem';
import { IVehicle } from '../interfaces/IVehicle';
import { IHeader, IContentfulResponse } from '../interfaces/IHeader';
import VehicleDetails from '../components/vehicles/VehicleDetails';

const Dashboard = () => {
    const { isAuthenticated } = useAuth0();

    const { loading, getData, cancelRequest } = useHttp();
    const [fleet, setFleet] = useState<Array<IVehicle>>([]);
    const [headers, setHeaders] = useState<Array<IHeader>>([]);
    const [vid, setVid] = useState("");

    const mergedBusData = fleet.map(bus => ({ ...bus, ...headers.find(header => header.fields.vid === bus.vid) }));

    useEffect(() => {
        getData("my/assets", {}, {
            "x-api-key": process.env.REACT_APP_VIRICITY_API_KEY
        })
            .then((res: Array<IVehicle>) => {
                setFleet(res);
                setVid(res[0].vid);
            })
            .catch((error) => {
                console.log(error);
            });

        return () => {
            cancelRequest();
        };
    }, []);

    const contentfulClient = async () => {
        const client = createClient({
            space: process.env.REACT_APP_CONTENTFUL_SPACE_ID as string,
            accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_KEY as string,
        })

        const res: IContentfulResponse = await client.getEntries({ content_type: 'ntsaHeaders' })
        setHeaders(res.items);
    };

    useEffect(() => {
        contentfulClient();
    }, [])


    const getVehicleDetails = (vid: string) => {
        setVid(vid);
    }

    return (
        <>
            {isAuthenticated && (
                <Box>
                <Navbar />
                <SplitScreen leftWeight={1} rightWeight={1}>
                  <LeftComponent>
                    <Grid item p={2}>
                      <Typography sx={{ pt: 2, pb: 2 }} variant='h4'>Fleet</Typography>
                      <TableContainer component={Paper}>
                        <Table aria-label="vehicle-details-table">
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 550 }}>Registration Number</TableCell>
                              <TableCell sx={{ fontWeight: 550 }}>E-Bus Name</TableCell>
                              <TableCell sx={{ fontWeight: 550 }}>E-Bus Make & Type</TableCell>
                              <TableCell sx={{ fontWeight: 550 }} colSpan={2}>E-Bus Owner</TableCell>
                              <TableCell sx={{ fontWeight: 550 }} colSpan={2}>Details</TableCell>
                            </TableRow>
                          </TableHead>
        
                          <TableBody>
                            {
                              loading ?
                                <TableRow>
                                  <TableCell colSpan={4}>
                                    <CircularProgress variant='indeterminate' size={12} /> <Typography variant='caption'>Loading bus data...</Typography>
                                  </TableCell>
                                </TableRow>
                                :
                                mergedBusData && mergedBusData?.map((vehicleData: any) => (
                                  <VehicleListItem key={vehicleData._id} vehicleData={vehicleData} getVehicleDetails={getVehicleDetails} />
                                ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </LeftComponent>
                  <RightComponent>
                    <Grid item p={2}>
                      <Typography sx={{ pt: 2, pb: 2 }} variant='h4'>Vehicle Details</Typography>
                      <VehicleDetails vehicle_vid={vid} mergedBusData={mergedBusData} />
                    </Grid>
                  </RightComponent>
                </SplitScreen>
              </Box>
            )}
        </>
    );
}

export default Dashboard
